<template lang="pug">
formTitle 
  .flex.justify-between.items-center
    | Tus medidas
    .metrics
      .type(
        :class="{ selected: metric == 'metric' }",
        @click="$store.dispatch('analysisStore/setMetric', 'metric')"
      ) Métrico
      .type(
        :class="{ selected: metric == 'imperial' }",
        @click="$store.dispatch('analysisStore/setMetric', 'imperial')"
      ) Imperial

.grid.grid-cols-1.gap-6.my-8
  inputNumber(
    labelText="Edad",
    :placeholder="27",
    v-model.number="age",
    :showError="errors.age",
    errorMessage="Debes ser mayor de 18 y menor de 99 años"
  )

  inputNumber(
    v-show="metric == 'metric'",
    labelText="Altura",
    :placeholder="175",
    subText="cm",
    v-model.number="height"
  )
  .grid.grid-cols-2.gap-6(v-show="metric == 'imperial'")
    inputNumber(
      labelText="Ft",
      :placeholder="5",
      subText="ft",
      v-model.number="feet"
    )
    inputNumber(
      labelText="In",
      :placeholder="11",
      subText="in",
      v-model.number="inches"
    )
  inputError.-mt-6(:showError="errors.height", :errorMessage="errorHeight")

  inputNumber(
    labelText="Peso actual",
    :placeholder="100",
    :subText="placeholderWeightMetric",
    v-model.number="weight",
    :showError="errors.weight",
    :errorMessage="errorWeight"
  )
  inputNumber(
    labelText="Peso objetivo",
    :placeholder="85",
    :subText="placeholderWeightMetric",
    v-model.number="objectiveWeight",
    :showError="errors.objectiveWeight",
    errorMessage="El peso objetivo no puede ser superior a tu peso."
  )
FormDirectionButtons.mt-6(
  v-on:back="$router.replace({ name: 'Quiz_4' })",
  v-on:next="goNextStep",
  :disableNext="false"
)
</template>

<script>
import FormDirectionButtons from "@/components/wizard/buttons-back-next.wizard";

export default {
  components: {
    FormDirectionButtons,
  },
  data: () => ({
    age: null,
    height: null,
    feet: null,
    inches: null,
    weight: null,
    objectiveWeight: null,
    errors: {
      age: false,
      height: false,
      weight: false,
      objectiveWeight: false,
    },
  }),
  watch: {
    age() {
      this.errors.age = false;
    },
    height() {
      this.errors.height = false;
    },
    feet() {
      this.errors.height = false;
    },
    inches() {
      this.errors.height = false;
    },
    metric() {
      this.errors.height = false;
    },
    weight() {
      this.errors.weight = false;
    },
    objectiveWeight() {
      this.errors.objectiveWeight = false;
    },
  },
  methods: {
    goNextStep() {
      if (!this.validationAge) {
        this.errors.age = true;
        return;
      }
      if (!this.validationHeight) {
        this.errors.height = true;
        return;
      }
      if (!this.validationWeigth) {
        this.errors.weight = true;
        return;
      }

      if (this.objectiveWeight > this.weight) {
        this.errors.objectiveWeight = true;
        return;
      }

      this.$store.dispatch("analysisStore/setMeasurement", {
        metric: this.metric,
        age: this.age,
        height: this.height,
        feets: this.feet,
        inches: this.inches,
        weight: this.weight,
        objectiveWeight: this.objectiveWeight,
      });
      this.$router.push({ name: "Quiz_loading" });
    },
  },
  computed: {
    metric() {
      return this.$store.getters["analysisStore/getMetric"];
    },
    placeholderWeightMetric() {
      if (this.metric == "metric") return "kg";
      else return "lb";
    },
    validationAge() {
      if (this.age && this.age >= 18 && this.age <= 99) return true;
      return false;
    },
    validationHeight() {
      if (this.metric == "metric" && this.height && this.height >= 100)
        return true;
      if (
        this.metric == "imperial" &&
        this.feet &&
        this.inches &&
        this.ftInToCm >= 100
      )
        return true;
      return false;
    },
    validationWeigth() {
      if (this.metric == "metric" && this.weight && this.weight >= 45)
        return true;
      if (this.metric == "imperial" && this.weight && this.weight >= 99)
        return true;
      return false;
    },
    validationObjectiveWeigth() {
      if (this.objectiveWeight && this.objectiveWeight < this.weight) {
        return true;
      } else {
        return false;
      }
    },

    errorHeight() {
      if (this.metric == "metric") {
        if (!this.height)
          return "Necesitamos tu altura para realizar los calculos correctos";
        if (this.height < 100)
          return "La altura debe ser superior a 100cm, o tambien puedes seleccionar el sistema metrico imperial (pies y pulgadas) en la esquina superior derecha.";
      } else if (this.metric == "imperial") {
        if (!this.feet || !this.inches) {
          return "Necesitamos tu altura para realizar los calculos correctos";
        }
        if (this.ftInToCm < 100) {
          return "La altura no es correcta, tambien puedes seleccionar el sistema metrico (cm) en la esquina superior derecha.";
        }
      }
      return "error..";
    },
    errorWeight() {
      if (this.metric == "metric")
        return "Tu peso es demasiado bajo, el valor minimo es 45kg";
      else return "Tu peso es demasiado bajo, el valor minimo es 99lb";
    },
    ftInToCm() {
      const ft_cm = this.feet * 30.48;
      const in_cm = this.inches * 2.54;
      return ft_cm + in_cm;
    },
  },
};
</script>

<style lang="scss" scoped>
.metrics {
  @apply flex items-center bg-gray-100 overflow-hidden text-sm rounded-full font-medium;

  .type {
    @apply px-3 py-1 cursor-pointer;

    &:hover {
      @apply bg-green-100;
    }

    &.selected {
      @apply bg-green-300;
    }
  }
}
</style>